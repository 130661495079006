
import { defineComponent, Ref, ref, watch, onMounted, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import SurveyQuestionModal from "@/components/modals/forms/dynamic_survey/SurveyQuestionModal.vue";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute } from "vue-router";
import {ElNotification} from "element-plus";
import { useBus } from "@/bus.ts";
import {DrawerComponent} from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "EventForm",
  components: {
    SurveyQuestionModal,
  },
  props: {
    data: { type: Object },
  },
  setup(props) {
    const route = useRoute();
    const { bus } = useBus();
    const formData = reactive({});

    const questionList = ref([]);
    const editData: Ref<{ survey_id: string | string[] }> = ref({ survey_id: '' });

    const create = (data) => {
      editData.value = { survey_id: route.params.id };
    };

    const edit = (data) => {
      editData.value = data;
    };

    const deleteQuestion = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiService.post(
                apiEndpoint.data().VUE_APP_QUESTION_DELETE,
                {
                  id: id,
                }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadQuestionList();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting output:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting output",
              type: "error",
            });
          }
        }
      });
    };

    const loadQuestionList = async () => {
      try {
        const id = route.params.id;
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_QUESTION_LIST,
          {
            survey_id: id,
          }
        );

        questionList.value = response.data.data;
      } catch (error) {
        console.error("Error loading data for update:", error);
      }
    };

    watch(
      () => route.params.id,
      (newData) => {
        if (newData) {
          loadQuestionList();
        }
      }
    );

    onMounted(() => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      if (route.params.id) {
        loadQuestionList();
      }

      bus.on("loadQuestionList", async () => {
        loadQuestionList();
      });
    });

    return {
      questionList,
      editData,
      create,
      edit,
      deleteQuestion,
      formData,
    };
  },
});
