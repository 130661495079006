
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "SurveyQuestionModal",
  props: {
    data: { type: Object },
    loadData: { type: Function },
  },
  setup(props) {
    const { bus } = useBus();
    const formData = ref({
      id: "",
      survey_id: "",
      updated_by: "",
      created_by: VueCookieNext.getCookie("_user_id"),
      title: "",
      input_type: "",
      input_data_type: "",
      question_options: [] as {
        title: string;
      }[],
    });

    const optionField = ref(false);

    const questionOptions = ref([
      { title: "" },
    ]);

    const addOption = () => {
      questionOptions.value.push({
        title: "",
      });
    };

    const removeOption = (index) => {
      if (questionOptions.value.length > 1) {
        questionOptions.value.splice(index, 1);
      }
    };

    watch(
      () => formData.value.input_type,
      (newInputType) => {
        optionField.value = newInputType == "single_input" || newInputType == "date_picker" ? false : true;
      }
    );

    watch(
        () => props.data,
        (newData) => {
          if (newData) {
            formData.value = {
              ...formData.value,
              ...newData,
            };
          }
        }
    );

    const schema = object().shape({
      title: string().required("Title is required"),
    });

    const errors = ref({});

    const save = async () => {
      try {
        if (formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }

        await schema.validate(formData.value, { abortEarly: false });

        formData.value.question_options = optionField.value ? (questionOptions.value !== null ? questionOptions.value : []) : [];

        ApiService.post(apiEndpoint.data().VUE_APP_QUESTION_STORE, formData.value)
          .then((response) => {
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              DrawerComponent.hideAll();
              bus.emit("loadQuestionList", true);
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        formData.value = {
          id: "",
          survey_id: "",
          updated_by: "",
          created_by: VueCookieNext.getCookie("_user_id"),
          title: "",
          input_type: "",
          input_data_type: "",
          question_options: [] as {
            title: string;
          }[],
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    return { formData, errors, save, questionOptions, addOption, removeOption, optionField };
  },
});
